<!--🔻 O banner possui duas variações quando no mobile:
       - O padrão é o banner mobile ser grande com a imagem 
         e os elementos flutuantes.
       - Quando com o modificador '--compact-mobile', ele 
         fica em uma versão com menos elementos, sem imagem
         e elementos flutuantes.-->
<div class="banner-main --compact-mobile">
  <div class="banner-main__wrapper">
    <section class="banner-main__content">

      <header class="banner-main__header">
        <a href="/" title="Ir para a página inicial">
          <picture>
            <source srcset="assets/logo-vss.webp" type="image/webp">
            <img src="assets/logo-vss.png"
              alt="Logo da Vale Saúde em formato de coração"
              width="85"
              height="72"
              loading="lazy"
              class="mb-24 mb-md-32 mb-lg-52">
          </picture>
        </a>

        <h1 class="dd-m-title-banner">
          Ative agora
          <span>mesmo seu cartão</span>
          <span> Vale Saúde Sempre</span>
        </h1>

        <app-button-contact></app-button-contact>
      </header>

      <footer height="550" class="banner-main__footer">
        <!--Container para a imagem principal -->
      </footer>

    </section>
  </div>

  <div class="banner-main__floating-elements"
    aria-hidden="true">
    <span class="banner-main__floating-elements-1"></span>
    <span class="banner-main__floating-elements-2"></span>
  </div>
</div>

<app-button-scroll-down></app-button-scroll-down>