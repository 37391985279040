import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonScrollDownComponent } from './button-scroll-down.component';
import { ScrollToModule } from './../../../directives/scroll-to/scroll-to.module';

@NgModule({
  declarations: [ButtonScrollDownComponent],
  imports: [CommonModule, ScrollToModule],
  exports: [ButtonScrollDownComponent],
})
export class ButtonScrollDownModule {}
