import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerMainComponent } from './banner-main.component';
import { ButtonContactModule } from '../../buttons/button-contact/button-contact.module';
import { ButtonScrollDownModule } from './../../buttons/button-scroll-down/button-scroll-down.module';

@NgModule({
  declarations: [BannerMainComponent],
  imports: [CommonModule, ButtonContactModule, ButtonScrollDownModule],
  exports: [BannerMainComponent],
})
export class BannerMainModule {}
