import { HttpErrorResponse } from '@angular/common/http';

export const handleError = (err: HttpErrorResponse): any => {
  let { fields, message } = err.error.error || err.error;

  if (!fields) {
    fields = err.error.errors;
  }

  return { fields, message };
};

/**
 * @description
 * Inclui um erro da api de um campo em seu respectivo estado.
 */
export const updateFormErrors = (form: any, errors: any) => {
  const { fields } = errors;
  if (fields) {
    for (const k in fields) {
      if (fields.hasOwnProperty(k) && form.controls[k]) {
        form.controls[k].setErrors({ custom: fields[k][0] });
      }
    }
  }
};
