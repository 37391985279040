import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appScrollTo]',
})
export class ScrollToDirective {
  @Input() elementId = '';

  constructor() {}

  @HostListener('click', ['$event']) onClick($event: any) {
    $event.preventDefault();
    this.scrollToSection(this.elementId);
  }

  scrollToSection(id: string) {
    let element = document.getElementById(id);

    if (element !== null)
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }
}
